import { ReactNode } from 'react'
import { connect } from 'react-redux'
import createAddForm from 'shared/utils/createAddForm'
import AddUserForm from 'shared/components/AddUserForm'
import inviteTeamSelectors from '../../selectors/inviteTeamSelectors'
import usersSelectors from 'shared/selectors/usersSelectors'
import { ADD_VET_TEAM_MEMBER } from '../../actionTypes'
import createInviteUserActions from 'shared/utils/inviteUser/createActions'
import settingsSelectors from '../../../shared/selectors/settingsSelectors'
import currentVetSelectors from '../../selectors/currentVetSelectors'
import { getFormValues, isSubmitting } from 'redux-form/immutable'
import { RecordOf } from 'immutable'
import RootState from 'shared/models/RootState'
import { createSelector } from 'reselect'

const {
  cancelInvitingUser,
  selectNewUser,
  selectUser
} = createInviteUserActions('inviteVetTeamMember')

const formName = 'buyer/vets/addTeamMember'
const formValueSelector = getFormValues(formName)

type FormValueProps = RecordOf<{ user: { email: string } }>

type ContainerProps = {
  userLookupLabel?: ReactNode
}

export const getDomains = createSelector(
  (state: RootState) => settingsSelectors.getField(state, 'domains'),
  (state: RootState) => settingsSelectors.getField(state, 'altRFIDomains'),
  (domains, altRFIDomains) => {
    const domainsList = domains && domains.toJS()
    if (altRFIDomains) {
      const altRFIDomainsList = altRFIDomains.toJS()
      return [...domainsList, ...altRFIDomainsList]
    }
    return domainsList
  }
)

const getInitialValues = createSelector(
  inviteTeamSelectors.getSelectedUser,
  inviteTeamSelectors.getNewUser,
  (selectedUser, newUser) => ({
    user: selectedUser || newUser
  })
)

const AddVetTeamMember = connect(
  (state: RootState, props: ContainerProps) => {
    const formValues = formValueSelector(state) as FormValueProps
    const creatingNewUser = inviteTeamSelectors.isCreatingNewUser(state)
    const teamIds = currentVetSelectors.getTeamMemberIds(state)

    return {
      caption: 'Invite user to an RFI',
      isSubmitting: isSubmitting(formName)(state),
      email: formValues && formValues.getIn(['user', 'email']),
      addingContact: inviteTeamSelectors.isAddingContact(state),
      creatingNewUser,
      domains: getDomains(state),
      users: usersSelectors.getColleagues(state),
      suggestedUsers: usersSelectors.getColleaguesExcludingUsers(
        state,
        teamIds
      ),
      initialValues: getInitialValues(state)
    }
  },
  {
    onClickCancel: cancelInvitingUser,
    onClickSuggestedUser: selectUser,
    onClickNewUser: selectNewUser
  }
)(
  createAddForm(
    {
      formName,
      submitActionType: ADD_VET_TEAM_MEMBER
    },
    AddUserForm
  )
)

export default AddVetTeamMember
